<template>
  <div class="wrapper flex align-start justify-between">
    <div class="flex-sub">
      <el-form :model="contractForm"
               label-width="140px"
               :rules="rules" ref="contractForm" class="add-form">
        <el-form-item label="姓名" prop="xingming">
          <el-input v-model="contractForm.xingming" placeholder="请填写姓名" disabled></el-input>
        </el-form-item>

        <el-form-item label="性别" prop="xingbie">
          <el-select v-model="contractForm.xingbie" disabled placeholder="请选择性别" class="w-all">
            <el-option
                v-for="item in sexList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证/通行证" prop="shenfenzhenghao">
          <el-input v-model="contractForm.shenfenzhenghao" disabled placeholder="请填写身份证/通行证"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="shoujihao">
          <el-input v-model="contractForm.shoujihao" placeholder="请填写手机"></el-input>
        </el-form-item>
        <el-form-item label="个人邮箱" prop="youxiang">
          <el-input v-model="contractForm.youxiang" placeholder="请填写邮箱"></el-input>
        </el-form-item>
        <el-form-item label="现居住地址" prop="xianjudizhi">
          <el-input v-model="contractForm.xianjudizhi" placeholder="请填写现居住地址"></el-input>
        </el-form-item>
        <el-form-item label="紧急联系人姓名" prop="jinjirenxingming">
          <el-input v-model="contractForm.jinjirenxingming" placeholder="请填写紧急联系人姓名"></el-input>
        </el-form-item>
        <el-form-item label="与本人关系" prop="yubenrenguanxi">
          <el-input v-model="contractForm.yubenrenguanxi" placeholder="请填写与本人关系"></el-input>
        </el-form-item>
        <el-form-item label="紧急联系人电话" prop="jinjirendianhua">
          <el-input v-model="contractForm.jinjirendianhua" placeholder="请填写紧急联系人电话"></el-input>
        </el-form-item>
        <el-form-item label="所在学校" prop="biyeyuanxiao">
          <el-input v-model="contractForm.biyeyuanxiao" placeholder="请填写毕业学校"></el-input>
        </el-form-item>
        <el-form-item label="专业" prop="zaixiaozhuanye">
          <el-input v-model="contractForm.zaixiaozhuanye" placeholder="请填写在校专业"></el-input>
        </el-form-item>
        <el-form-item label="法定送达地址" prop="internMailAddress">
          <el-input v-model="contractForm.internMailAddress" placeholder="请填写法定送达地址"></el-input>
        </el-form-item>
        <el-form-item label="法定送达地址邮编" prop="internMailCode">
          <el-input v-model="contractForm.internMailCode" placeholder="请填写法定送达地址邮编"></el-input>
        </el-form-item>
        <el-form-item label="岗位名称" prop="gangweimingcheng">
          <el-input v-model="contractForm.gangweimingcheng" placeholder="请填写岗位名称"></el-input>
        </el-form-item>

        <el-form-item label="实习周期" prop="shixizhouqi">
          <el-input v-model="contractForm.shixizhouqi" placeholder="请填写实习周期" type="number">
            <template slot="append">个月</template>
          </el-input>
        </el-form-item>

        <el-form-item label="实习时间" prop="contractDate">
          <el-date-picker class="w-all"
                          v-model="contractForm.contractDate"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="实习开始日期"
                          end-placeholder="实习结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="实习方式" prop="gongzuofangshi">
          <el-select v-model="contractForm.gongzuofangshi" placeholder="请选择实习方式" class="w-all">
            <el-option
                v-for="item in  wayOfWorking"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="周不少于天" prop="internWeeklyDays">
          <el-input v-model="contractForm.internWeeklyDays" placeholder="请填写周不少于天(每天按照8小时计算)"
                    type="number">
            <template slot="append">天</template>
          </el-input>
        </el-form-item>


        <!--        <el-form-item label="入职日期" prop="daoqishijian">-->
        <!--          <el-date-picker align="center"-->
        <!--              class="w-all"-->
        <!--              v-model="contractForm.daoqishijian"-->
        <!--              type="date"-->
        <!--              placeholder="请选择入职日期">-->
        <!--          </el-date-picker>-->
        <!--        </el-form-item>-->

        <el-form-item label-width="0" class="flex justify-around">
          <el-button class="submit-btn" type="primary" @click="submitForm('contractForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-sub flex align-center justify-between flex-direction preview padding-lr">
      <div class="preview-info">
        <div class="preview-tips" v-if="!sys_offer_file">保存后可预览</div>
        <iframe v-else ref="iframe" frameborder="0" style="width: 100%;height: calc(100vh - 335px);"></iframe>
      </div>

      <div class="flex align-center justify-around margin-top">
        <el-button class="submit-btn margin-right" type="primary" @click="downOffer">下载实习协议
        </el-button>
        <el-upload
            ref="upBtn"
            class="upload-demo margin-right"
            :action="actions"
            :data="{token}"
            :on-success="handleSuccess"
            :limit="1"
            :show-file-list="false"
        >
          <el-button class="submit-btn" type="warning">上传实习协议反馈</el-button>
        </el-upload>

      </div>
      <div class="flex align-center justify-center margin-top" v-if="upload_file">
        <div>反馈附件：</div>
        <el-link v-if="upload_file" :href="upload_file" target="_blank" type="primary">
          下载实习协议反馈附件
        </el-link>
      </div>
    </div>

  </div>
</template>

<script>

import rules from "@/utils/rules";
import {QAHOST, upFile} from "@/config";
import {downLoadOfferWord, protocolShow, replyProtocolFile, staffPracticeProtocol} from "@/api/entry";
import {getPageName, zhDate} from "@/utils";
import {getOfferHtml} from "@/api/common";
import {sexList, wayOfWorking} from "@/utils/const";

export default {
  name: "OrgList",
  data() {
    return {
      id: '',
      sexList: sexList,
      wayOfWorking: wayOfWorking,
      contractForm: {
        xingming: '',
        xingbie: '',
        biyeyuanxiao: '',
        zaixiaozhuanye: '',
        shenfenzhenghao: '',
        shoujihao: '',
        youxiang: '',
        xianjudizhi: '',
        jinjirenxingming: '',
        yubenrenguanxi: '',
        jinjirendianhua: '',
        internMailAddress: '',
        internMailCode: '',
        gangweimingcheng: '',
        shixizhouqi: '',
        contractDate: '',
        gongzuofangshi: '',
        internWeeklyDays: '',
        daoqishijian: '',
      },
      rules: {
        xingming: rules.mustInput,
        xingbie: rules.mustSelect,
        biyeyuanxiao: rules.mustInput,
        zaixiaozhuanye: rules.mustInput,
        shenfenzhenghao: rules.mustInput,
        shoujihao: rules.mustInput,
        youxiang: rules.mustInput,
        xianjudizhi: rules.mustInput,
        jinjirenxingming: rules.mustInput,
        yubenrenguanxi: rules.mustInput,
        jinjirendianhua: rules.mustInput,
        internMailAddress: rules.mustInput,
        internMailCode: rules.mustInput,
        gangweimingcheng: rules.mustInput,
        shixizhouqi: rules.mustInput,
        contractDate: rules.mustArray,
        gongzuofangshi: rules.mustSelect,
        internWeeklyDays: rules.mustInput,
        daoqishijian: rules.mustDate,

      },
      isSubmit: true,
      sys_offer_file: '',
      upload_file: '',
      iframe: '',
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    }
  },
  created() {
    this.$emit("setIndex", '/admin/expertList', "实习生实习协议", true);
    this.id = Number(this.$route.params.id)
    this.protocolShow()
  },
  components: {},
  methods: {
    async protocolShow() {
      const res = await protocolShow(this.id, 2)
      this.sys_offer_file = res.data.sys_offer_file
      this.upload_file = res.data.upload_file ? QAHOST + '/' + res.data.upload_file : ''
      this.contractForm = {
        xingming: res.data.xingming,
        xingbie: res.data.xingbie ? Number(res.data.xingbie) : '',
        biyeyuanxiao: res.data.biyeyuanxiao,
        zaixiaozhuanye: res.data.zaixiaozhuanye,
        shenfenzhenghao: res.data.shenfenzhenghao,
        shoujihao: res.data.shoujihao,
        youxiang: res.data.youxiang,
        xianjudizhi: res.data.xianjudizhi,
        jinjirenxingming: res.data.jinjirenxingming,
        yubenrenguanxi: res.data.yubenrenguanxi,
        jinjirendianhua: res.data.jinjirendianhua,
        internMailAddress: res.data.internMailAddress,
        internMailCode: res.data.internMailCode,
        gangweimingcheng: res.data.gangweimingcheng,
        shixizhouqi: res.data.shixizhouqi,
        contractDate: res.data.internJoinDate ? [new Date(res.data.internJoinDate), new Date(res.data.internResignDate)] : '',
        gongzuofangshi: res.data.gongzuofangshi ? Number(res.data.gongzuofangshi) : '',
        internWeeklyDays: res.data.internWeeklyDays,
        daoqishijian: res.data.daoqishijian ? new Date(res.data.daoqishijian) : ''


      }
      if (res.data.sys_offer_file) {
        const iframe = await getOfferHtml(getPageName(res.data.sys_offer_file))
        this.iframe = QAHOST + '/' + iframe.data + '?v=' + new Date().getTime()
        this.$refs.iframe.contentWindow.location.replace(this.iframe)
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await staffPracticeProtocol(
        this.id,

        this.contractForm.xingming,
        this.contractForm.xingbie,
        this.contractForm.biyeyuanxiao,
        this.contractForm.zaixiaozhuanye,
        this.contractForm.shenfenzhenghao,
        this.contractForm.shoujihao,
        this.contractForm.youxiang,
        this.contractForm.xianjudizhi,
        this.contractForm.jinjirenxingming,
        this.contractForm.yubenrenguanxi,
        this.contractForm.jinjirendianhua,
        this.contractForm.internMailAddress,
        this.contractForm.internMailCode,
        this.contractForm.gangweimingcheng,
        this.contractForm.shixizhouqi,
        zhDate(new Date(this.contractForm.contractDate[0])),
        zhDate(new Date(this.contractForm.contractDate[1])),
        this.contractForm.gongzuofangshi,
        this.contractForm.internWeeklyDays,
        this.contractForm.daoqishijian,
      )

      if (_state.status === 200) {
        this.$message.success(_state.info)
        await this.protocolShow()
      } else {
        this.$message.error(_state.info)
      }
    },
    //  下载附件
    async downOffer() {
      if (!this.iframe) return this.$message.error('请编辑保存后生成')
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let _data = await downLoadOfferWord(this.id, this.sys_offer_file)
      loading.close();
      let blob = _data.data
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.style.display = 'none';
      downloadElement.href = href;
      downloadElement.setAttribute('download', this.contractForm.xingming + '实习协议.docx');
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    },
    handleSuccess(file) {
      if (file.status === 200) {
        this.upFile(file.data)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    async upFile(url) {
      await replyProtocolFile(this.id, 2, url, '')
      this.$message.success('上传成功')
      await this.protocolShow()
    },
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.preview {
  height: 100%;

  .preview-title {
    text-align: center;
    line-height: 60px;
    font-size: 22px;
  }

  .preview-info {
    width: 100%;
    height: calc(100vh - 330px);
    overflow-y: auto;
    padding: 0 30px;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
  }
}
</style>
